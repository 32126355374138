import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import moment from "moment";
import { ObservationDto } from "src/app/common/dtos/observation/observation.dto";

@Injectable({
    providedIn: "root"
})
export class ExportService {

    downloadCSV(data:ObservationDto[]) {
        const header = "Date;Name;Surname;Company;JobPosition;Project;Activity;Place;Observation;" +
        "SUGGESTED ACTIONS / CORRECTIVE ACTIONS TAKEN;Topic;observationReference;Stato";
        const rows = data.map((item) => {
            return [
                this.formatDate(item.insert_date),
                item.first_name,
                item.last_name,
                item.company,
                item.role,
                item.project,
                item.activity?.replace(";", "."),
                item.location?.replace(";", "."),
                item.observation?.replace(";", "."),
                item.action?.replace(";", "."),
                item.activity_type,
                item.observation_reference?.join(","),
                item.status_description
            ].map((field) => `${field}`).join(";");
        });
        const csvContent = [
            header, ...rows
        ].join("\n");
        const blob = new Blob([
            csvContent
        ], { type: "text/csv;charset=utf-8;" });
        FileSaver.saveAs(blob, "ObservationCard.csv");
    }

    formatDate(dateString: string | Date): string {
        if (dateString instanceof Date) {
            return moment(dateString).format("DD-MM-YYYY HH:mm:ss");
        }
        return moment(new Date(dateString)).format("DD-MM-YYYY HH:mm:ss");
    }

    isObject(myVariable: unknown): boolean {
        return typeof myVariable === "object" && myVariable !== null;
    }
}